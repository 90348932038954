export const IsDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const FrontendUrl = process.env.REACT_APP_SPITZ_URL;
const Url = process.env.REACT_APP_API_BASE_URL;
const AppSettings = {
    ServiceUrl: Url + "/api",
    SignalRUrl: Url + "/clienthub",
    SoundUrl: Url + "/Files/Sound_Files/",
    NotifierLimit: 3,
    RobotNotifierLimit: 1,
    RobotTrackLink: "delivers.ai/#/purchaser?orderkey=26374937&access=jr84p23s129-21kf3",
    RobotMaxLinear: 1.5, // m/s
    RobotMaxAngular: 0.6, // rad/s
    RobotMaxPureAngular: 0.3,
    Precision: 8,
    WaypointSafeDistance: 1.2,
    LidKey: "20DeliverBond22",
    HighResolutionTopic: "/image_stitcher_node/stitched_image/high_quality/compressed",
    AWSPeriodicImageUrl: "https://robot-periodic-image.s3.eu-central-1.amazonaws.com/robot-",
    YOLODetector: "/yolov4/traffic_light_publisher/detections",
    VehicleDetector: "/vehicle_notifier/warnings",
    LandmarkLocalizationNode: "/landmark_localization_node/successfull_match",
    AWSLandmarkImageUrl: "https://aws-landmark-image.s3.eu-central-1.amazonaws.com/",
    AWSLandmarkBucketName: "aws-landmark-image",
    RepositoryProjectId: 39432486,
    EvriStoreId: process.env.REACT_APP_EVRI_STORE,
    CloseLidTimeout: 240
}

export default AppSettings;
